<template>
  <v-dialog
    max-width="700"
    width="100%"
    :value="true"
    @click:outside="close"
    @keydown.esc="close"
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar dark class="mb-2">
          <v-btn icon>
            <v-icon>mdi-calendar-text</v-icon>
          </v-btn>
          <v-toolbar-title
            >Portfolio:
            {{
              portfolio.type ? portfolio.type.description : ""
            }}</v-toolbar-title
          >
          <v-spacer />
          <v-btn @click="back" icon>
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-4">
        <RowItem align="center" title="Titel">
          <h3>{{ portfolio.title }}</h3>
        </RowItem>
        <RowItem align="center" title="erstellt von">
          <PersonItem :value="portfolio.creator" />
        </RowItem>
        <RowItem align="center" title="erstellt für">
          <PersonItem :value="portfolio.student" />
        </RowItem>
        <RowItem align="center" title="erstellt am">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <DateValue :value="portfolio.creationDate" />,

                <TimeValue :value="portfolio.creationTime"></TimeValue>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </RowItem>
        <RowItem title="Text">
          <Markdown :value="portfolio.text" />
        </RowItem>
        <RowItem title="versteckt">
          <v-simple-checkbox disabled :value="portfolio.hidden" />
        </RowItem>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="edit">bearbeiten</v-btn>
        <v-spacer />
        <ReportButton
          color="primary"
          resource="report/portfolio"
          :parameters="{
            type: 'portfolio',
            id: portfolio.id,
            orientation: 'portrait',
          }"
        />
        <v-spacer />
        <v-btn text @click="close">schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateValue from "common/components/DateValue.vue";
import Markdown from "@/components/Markdown.vue";
import PersonItem from "@/components/PersonItem.vue";
import ReportButton from "@/components/ReportButton.vue";
import RowItem from "@/components/RowItem.vue";
import TimeValue from "common/components/TimeValue.vue";

export default {
  name: "PortfolioDetail",
  props: ["id"],
  components: {
    DateValue,
    Markdown,
    PersonItem,
    ReportButton,
    RowItem,
    TimeValue,
  },
  data() {
    return { portfolio: {}, loading: false };
  },
  methods: {
    edit() {
      this.$router.push({
        name: "PortfolioEdit",
        params: { id: this.portfolio.id },
      });
    },
    back() {
      this.$router.back();
    },
    close() {
      this.$router.push({
        name: "PortfolioList",
      });
    },
  },
  async created() {
    this.loading = true;
    this.portfolio = await this.apiGet({
      resource: "portfolio/portfolio",
      id: this.id,
    });
    this.loading = false;
  },
};
</script>
